import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import type { ActionResultListCommuneResponse } from '@portal/models/actionResultListCommuneResponse';
import type { ActionResultListCountryResponse } from '@portal/models/actionResultListCountryResponse';
import type { ActionResultListDistrictResponse } from '@portal/models/actionResultListDistrictResponse';
import type { ActionResultListProvinceResponse } from '@portal/models/actionResultListProvinceResponse';
import type { ActionResultListVillageResponse } from '@portal/models/actionResultListVillageResponse';
import type { CommuneResponse } from '@portal/models/communeResponse';
import type { CountryResponse } from '@portal/models/countryResponse';
import type { DistrictResponse } from '@portal/models/districtResponse';
import { ProvinceResponse } from '@portal/models/provinceResponse';
import type { VillageResponse } from '@portal/models/villageResponse';
import { addressesContext } from '@portal/api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  constructor(private readonly http: HttpClient) {}

  loadCountries(query?: string): Observable<CountryResponse[]> {
    const params = new HttpParams({
      fromObject: {
        query: query || '',
      },
    });
    return this.http
      .get<ActionResultListCountryResponse>(addressesContext.countries, { params })
      .pipe(map(r => r.value));
  }

  loadRegions(countryId: number, query?: string): Observable<ProvinceResponse[]> {
    const params = new HttpParams({
      fromObject: {
        countryId,
        query: query || '',
      },
    });

    return this.http
      .get<ActionResultListProvinceResponse>(addressesContext.provinces, { params })
      .pipe(map(res => res.value));
  }

  loadDistricts(provinceId: number, query?: string): Observable<DistrictResponse[]> {
    const params = new HttpParams({
      fromObject: {
        provinceId,
        query: query || '',
      },
    });

    return this.http
      .get<ActionResultListDistrictResponse>(addressesContext.districts, { params })
      .pipe(map(res => res.value));
  }

  loadAreas(districtId: number, query?: string): Observable<CommuneResponse[]> {
    const params = new HttpParams({
      fromObject: {
        districtId,
        query: query || '',
      },
    });

    return this.http
      .get<ActionResultListCommuneResponse>(addressesContext.communes, { params })
      .pipe(map(res => res.value));
  }

  loadCities(communeId: number, query?: string): Observable<VillageResponse[]> {
    const params = new HttpParams({
      fromObject: {
        communeId,
        query: query || '',
      },
    });

    return this.http
      .get<ActionResultListVillageResponse>(addressesContext.villages, { params })
      .pipe(map(res => res.value));
  }
}
