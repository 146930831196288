import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, fromEvent, interval, timer } from 'rxjs';
import { debounceTime, filter, map, retry, startWith, switchMap } from 'rxjs/operators';
import type { ActionResultChatSummaryResponse } from '@portal/models/actionResultChatSummaryResponse';
import type { ChatSummaryResponse } from '@portal/models/chatSummaryResponse';
import { wsChatsContext } from '@portal/api-endpoints';
import { getHttpParams } from '@portal/core';

@Injectable({
  providedIn: 'root',
})
export class UnreadMessagesService {
  private readonly chatsSummarySubject = new BehaviorSubject<ChatSummaryResponse>(null);
  public chatsSummary$: Observable<ChatSummaryResponse> = this.chatsSummarySubject.asObservable();
  private readonly MESSAGES_REFRESH_INTERVAL = 60_000;

  constructor(private readonly http: HttpClient, @Inject(DOCUMENT) private readonly document: Document) {
    this.setUnreadMessageCounters();
  }

  private setUnreadMessageCounters(): void {
    const params = getHttpParams({ skipLoaderLock: true, skipErrorHandling: true });

    const interval$ = interval(this.MESSAGES_REFRESH_INTERVAL).pipe(startWith(0));
    const windowBecameActive$ = fromEvent(this.document.defaultView.window, 'visibilitychange').pipe(startWith(0));

    combineLatest([interval$, windowBecameActive$])
      .pipe(
        debounceTime(100),
        filter(() => !this.document.hidden),
        switchMap(() => this.http.get<ActionResultChatSummaryResponse>(wsChatsContext.summary, { params })),
        retry({
          delay: (_, retryCount) => timer(this.MESSAGES_REFRESH_INTERVAL * retryCount),
          resetOnSuccess: true,
        }),
        map(res => res.value),
      )
      .subscribe(this.chatsSummarySubject);
  }

  getUnreadMessagesCount(chatId?: number): Observable<number> {
    return this.chatsSummary$.pipe(map(sum => sum.chatId2ChatInfo[chatId]?.unreadMessages));
  }
}
